
import React, { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {Typography} from '@mui/material';
import Container from '@mui/material/Container';
import Nav from './components/nav';
import Home from './pages/home';
import Opportunites from './pages/opportunites';
import Part from './pages/part';
import Parts from './pages/parts';
import Network from './pages/network';
import AddPart from './pages/addPart';
import Schedule from './pages/schedule';
import Fleet from './pages/fleet';
import Login from './pages/login';
import Logout from './pages/logout';
import Consolidations from './pages/consolidations';
import NetworkDesign from './pages/networkDesign'; 
import UploadFleet from './pages/uploadFleet';
import MoreInfo from './pages/moreInfo';
import Details from './pages/detail';
import InProcessConsols from './pages/inProcessConsols';
import NotFound from './pages/notFound';
import ConsoleDetail from './pages/consolDetail';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";


const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.75rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  palette: {
    primary: {
      main: '#284db7',
    },
    secondary: {
      main: '#612785',
    },
    
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      custom: 1024,
      surface: 912,
      nest: 1280,
      ipad:768
    },
  },
});

function App() {
  const [hasFleet, setHasFleet] = React.useState(
    JSON.parse(localStorage.getItem('logistics_manager_has_fleet') || 'false')
  );
  const [loggedIn, setLoggedIn] = React.useState(
    !!localStorage.getItem('logistics_manager_token')
  );
  const [token, setToken] = React.useState(
    localStorage.getItem('logistics_manager_token') || undefined
  );

  useEffect(() => {
    // Update logged-in state if token exists
    if (token) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [token]);

  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h1" component="h1" style={{display:'none'}}>{loggedIn}</Typography>
        <>
              <BrowserRouter>
                <Nav loggedIn={loggedIn}/>
                <Container  sx={{
                  maxWidth: {
                    xs: '100%',   // Full width on extra small screens
                    sm: '100%',   // Full width on small screens
                    md: '100%',    // 90% width on medium screens
                    lg: '100%',    // 80% width on large screens
                    xl: '100%',    // 70% width on extra large screens
                  },
                }}>
                  <Routes>
                    <Route path="/" element={<Home />}/>
                    <Route path="/opportunites" element={hasFleet?<Opportunites token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/network" element={hasFleet?<Network token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/network/design" element={hasFleet?<NetworkDesign token={token}/>:<Navigate to="/upload/fleet"/>}/>
                    <Route path="/fleet/" element={hasFleet?<Fleet token={token}/>:<Navigate to="/upload/fleet/"/>} />
                    <Route path="/recommended/consolidations" element={hasFleet?<Consolidations token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/part/:id" element={hasFleet?<Part token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/parts/" element={hasFleet?<Parts token={token} />:<Navigate to="/upload/fleet"/>} />
                    <Route path="/part/add" element={hasFleet?<AddPart token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/schedule/:imo" element={hasFleet?<Schedule token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/ship/detail/:imo" element={hasFleet?<Details token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/login" element={<Login setToken={setToken} setLoggedIn={setLoggedIn} setHasFleet={setHasFleet}/>} />
                    <Route path="/inprocess/consols" element={hasFleet?<InProcessConsols token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/consol/detail/:id" element={hasFleet?<ConsoleDetail token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/upload/fleet" element={<UploadFleet/>} />
                    <Route path="/more/info" element={<MoreInfo />} />
                    <Route path="/logout" element={<Logout setLoggedIn={setLoggedIn}/>} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Container>
              </BrowserRouter>
        </>
    </ThemeProvider>
  );
}

export default App;