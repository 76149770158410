import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {Typography, Box,  MenuItem, Menu } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';

export default function Nav({loggedIn}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [consolidationAnchorEl, setConsolidationAnchorEl] = React.useState(null);
    const isConsolidationMenuOpen = Boolean(consolidationAnchorEl);
    const[loggedInState, setLoggedIn] = React.useState(false);
  
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleConsolidationMenuClose = () => {
        setConsolidationAnchorEl(null);
        handleMenuClose();
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
      setConsolidationAnchorEl(null);
    };

    const renderConsolidationMenu = (
      <Menu
        anchorEl={consolidationAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        id={"consolidation-menu"}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isConsolidationMenuOpen}
        onClose={handleConsolidationMenuClose}
      >
          <Link to="/recommended/consolidations" style={{ textDecoration: 'none', color:'black' }}>
            <MenuItem onClick={handleConsolidationMenuClose}>Recommended Consolidations</MenuItem>
          </Link>
          <Link to="/inprocess/consols" style={{ textDecoration: 'none', color:'black' }}>
            <Tooltip title="A grouping of individual shipments into one large consolidated shipment" arrow placement="bottom">
              <MenuItem onClick={handleConsolidationMenuClose}>In Process Consols</MenuItem>
              </Tooltip>
          </Link>
      </Menu>
    );

    React.useEffect(() => {
        setLoggedIn(loggedIn);
    },[loggedIn]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
            <Box sx={{ flexGrow: 1, display:{xs:'block', sm:'block', md:'none', lg:'none'}}}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={handleMenuOpen}
                >
                  <MenuIcon />
                </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                      <MenuItem component={Link} to="/" onClick={handleMenuClose}> Home</MenuItem>
                      <MenuItem component={Link} to="/opportunites" onClick={handleMenuClose}>Opportunites</MenuItem>

                      <MenuItem onClick={(event) => {setConsolidationAnchorEl(event.currentTarget)}}>Consolidations</MenuItem>
                      {renderConsolidationMenu}
                      <MenuItem component={Link} to="/parts" onClick={handleMenuClose}>Stock</MenuItem>
                      <MenuItem component={Link} to="/fleet" onClick={handleMenuClose}>Fleet</MenuItem>
                        {loggedInState?<MenuItem component={Link} to="/logout">Logout</MenuItem>:<MenuItem component={Link} to="/login">Login</MenuItem>}
                    </Menu>
            </Box>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            LogisticsManager
          </Typography>
          <Box sx={{ display: { xs: 'none', sm:'none', md: 'block' } }}>
                  <Button component={Link} to="/" color="inherit">Home</Button>
                {loggedInState?
                    <>
                      <Button component={Link} to="/opportunites" color="inherit">Opportunites</Button>
                      <Button color="inherit" onClick={(event)=> setConsolidationAnchorEl(event.currentTarget)}>Consolidations</Button>
                      {renderConsolidationMenu}
                      <Button component={Link} to="/parts" color="inherit">Stock</Button>
                      <Button component={Link} to="/fleet" color="inherit">Fleet</Button>
                      <Button component={Link} to="/network" color="inherit">Network</Button>
                      <Button component={Link}  to="/network/design" color="inherit">Network design</Button>
                      <Button component={Link} to="/logout" color="inherit">Logout</Button>
                    </>
                    :
                    <Button  component={Link} to="/login" color="inherit">Login</Button>

                }
         </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}