import React from 'react';
import {Typography, Box,  Button} from '@mui/material';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';



export default function Home(){
    return (   
      <> 
          <Box sx={{ minWidth:'100%',background:'#0c2187',marginTop:'20px', minHeight: '450px',}}>
              <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={6} ipad={12} surface={12} custom={12} >
                        <Box sx={{ 
                              width:'100%', 
                              backgroundImage: 'url(/logistics.png)', 
                              backgroundSize: 'cover', 
                              backgroundRepeat: 'no-repeat', 
                              height: '450px',
                              display:{xs:'none',sm:'none',md:'block',lg:'block', ipad:'block'}
                          }}/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} ipad={12} surface={12} custom={12}>
                        <Typography variant="h1" component="h1" sx={{color:'white', fontWeight:'bold', fontSize:'2rem', textAlign:'left', margin:'30px'}}>
                          Improve Communication between Suppliers and Logistics Providers
                        </Typography>
                        <Typography variant="body1" component="p" sx={{color:'white', margin:'30px', textAlign:'left'}}>
                          Keeping a fleet of vessels operational depends on the management of
                          spare parts and other supplies. LogisticsManager enables
                          communication between suppliers and logistics providers 
                          powered by AIS data to save costs and improve efficiency.
                        </Typography>
                        <Button variant="outlined" size="large" sx={{marginLeft:'30px' , marginBottom:'20px', color:'#ffffff', backgroundColor:'#5f71f778'}}>
                          REQUEST A DEMO
                        </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} ipad={12}></Grid>
              </Grid>
          </Box>
          <Box sx={{ minWidth:'100%',background: '#f9f9f9',marginTop:'20px', minHeight: '500px',marginBottom:'20px'}}>
              <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={6} ipad={12}  surface={12} custom={12}>
                      <Box sx={{ 
                                width:{sm:'100%', xs:'100%', md:'100%',ipad:'100%'}, 
                                backgroundImage: 'url(/fleet.jpg)', 
                                backgroundSize: 'cover', 
                                backgroundRepeat: 'no-repeat', 
                                height: '500px',
                                display:{xs:'none',sm:'none',md:'block',lg:'block', ipad:'block'}
                        }}/> 
                  </Grid>    
                  <Grid item xs={12} sm={12} md={4} lg={4} ipad={12} sx={{marginBottom:'50px'}}  surface={12} custom={12}>
                      <Typography variant="h2" component="h2" sx={{color:'black', fontWeight:'bold', fontSize:'2rem', margin:'30px', textAlign:'left'}}>
                      Are you managing a fleet of vessels or incurring high costs in delivering spares and supplies to them?
                        </Typography>
                        <Typography variant="body2" component="p" sx={{color:'black', margin:'30px', fontSize:'1.3rem', textAlign:'left'}}>
                              Nisomar's Logistics Manager enhances collaboration and streamlines communication 
                              across all the parties involved - procurement, logistics and local agents.
                        </Typography>
                        <Link to="/more/info" style={{textDecoration:'none'}}>
                          <Button variant="contained" size="large" color='primary' sx={{marginLeft:'30px'}}>Read More...</Button>
                        </Link>
                    </Grid>            
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} ipad={12}></Grid>
          </Box>

      </>
              
    );
}