import React from 'react';
import {Typography, Card, CardContent, Divider, Box} from '@mui/material';
import Grid from '@mui/material/Grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AppsIcon from '@mui/icons-material/Apps';
import WarningIcon from '@mui/icons-material/Warning';
import Chip from '@mui/material/Chip';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import BuildIcon from '@mui/icons-material/Build';
import MemoryIcon from '@mui/icons-material/Memory';


export default function MoreInfo(){
    return (   
      <> 
              <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} ipad={12}>
                        <Typography variant="h2" component="p" color='secondary' sx={{ fontWeight:'bold', fontSize:'4rem', marginTop:'10px', textAlign:'center', textDecoration:'underline'}}>WHY USE THE APP?</Typography>
                        <Typography variant="body1" component="p" sx={{marginTop:'5px', fontSize:'1.5rem', textAlign:'center'}}>
                              Enhance collaboration and streamline communication between suppliers and logistics operations.
                        </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} ipad={12}></Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} ipad={12}>
                       <Grid container>
                            <Grid item xs={12} sm={12} md={6} lg={6} ipad={12} custom={12} surface={12} sx={{marginTop:'50px'}}>
                                <Chip label="Current Process" sx={{margin:'10px', alignItems:'center', fontSize:'1.2rem', backgroundColor:'purple', color:'white' , textAlign:'center' , borderRadius:'5px'}}/>
                                <Card 
                                  sx={{margin:'10px', backgroundColor:'#718d881f', borderRadius:'5px'}}
                                  >
                                    <CardContent>
                                      
                                      <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: '2rem', textAlign:'center'}}>
                                        <ShoppingCartIcon sx={{fontSize:'1.5rem'}}/> Context
                                      </Typography>
                                      <Typography variant="body1" component="p" sx={{textAlign:'left', fontSize:'1.3rem'}}>Spares and other supplies are ordered regularly to maintain a fleet of ships.</Typography>
                                    </CardContent>
                                </Card>
                                <ArrowDropDownIcon sx={{fontSize:'2.5rem', marginLeft:'45%'}}/>
                                <Card 
                                  sx={{margin:'10px', backgroundColor:'#db0000', borderRadius:'5px'}}
                                  >
                                    <CardContent>
                                      
                                      <Typography gutterBottom sx={{fontSize: '2rem', textAlign:'center', color:'white'}}>
                                        <WarningIcon sx={{fontSize:'1.5rem', color:'white', marginRight:'1%'}}/>
                                        Problems
                                      </Typography>
                                      <Typography variant="body1" component="p" sx={{fontSize:'1.3rem', textAlign:'left', color:'white'}}>
                                        The logistics operations almost never get notified in time to make informed decisions. When notification is received it almost never
                                        matches the current status of the vessel, causing
                                        the logistics supplier to have to use a more expensive method of delivery in order to meet delivery.
                                      </Typography>
                                      <Typography variant="body1" component="p" sx={{marginTop:'30px'}}></Typography>
                                    </CardContent>
                                </Card>
                                <ArrowDropDownIcon sx={{fontSize:'2.5rem', marginLeft:'45%'}}/>
                                <Card 
                                  sx={{margin:'10px', backgroundColor:'#718d881f', borderRadius:'5px'}}
                                  >
                                    <CardContent>
                                      
                                      <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: '2rem', textAlign:'center'}}>
                                        <LocalShippingIcon sx={{fontSize:'1.5rem'}}/> Managing Logistics
                                      </Typography>
                                      <Typography variant="body1" component="p" sx={{fontSize:'1.3rem', textAlign:'left'}}>
                                         In order to deliver the part the logistics supplier has to make a quick decision on the type of delivery and cost
                                         based on the information they have at hand.
                                      </Typography>
                                      <Typography variant="body1" component="p" sx={{marginTop:'20px', fontSize:'1.3rem'}}>
                                        In many cases this is received so late that parts must be shipped individually using express services.
                                        In the worst case the spare is delivered to the port after the ship has sailed!
                                      </Typography>
                                    </CardContent>
                                </Card>
                              
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} ipad={12} custom={12} surface={12} sx={{marginTop:'50px'}}>
                                <Chip label="Using the App" sx={{margin:'10px', alignItems:'center', fontSize:'1.2rem', backgroundColor:'green', color:'white' , textAlign:'center' , borderRadius:'5px'}}/>
                                <Card 
                                sx={{margin:'10px', backgroundColor:'#e1ffe1', borderRadius:'5px'}}
                                >
                                  <CardContent>
                                    
                                    <Typography gutterBottom sx={{fontSize: '2rem', textAlign:'center'}}>
                                      <ShoppingCartIcon sx={{fontSize:'1.5rem'}}/> Context
                                    </Typography>
                                    <Typography variant="body1" component="p" sx={{textAlign:'left', fontSize:'1.3rem'}}>Spares and other supplies are ordered regularly to maintain a fleet of ships.</Typography>
                                  </CardContent>
                                </Card>
                                <ArrowDropDownIcon sx={{fontSize:'2.5rem', marginLeft:'45%'}}/>
                                <Card 
                                  sx={{margin:'10px', backgroundColor:'#4ea54e', borderRadius:'5px'}}
                                  >
                                    <CardContent>
                                      
                                      <Typography gutterBottom sx={{fontSize: '2rem', textAlign:'center', color:'white'}}>
                                        <AppsIcon sx={{fontSize:'1.5rem', color:'white', marginRight:'1%'}}/>
                                        Solution
                                      </Typography>
                                      <Typography variant="body1" component="p" sx={{fontSize:'1.3rem', textAlign:'left', color:'white'}}>
                                        The app will ingest the order creation information via an automated process or via manual entry. Once this information is received the logistics operations will be notified of
                                        consolidaion opportunities and can make informed decisions with an aim of saving costs and improving efficiency.
                                      </Typography>
                                    </CardContent>
                                </Card>
                              <ArrowDropDownIcon sx={{fontSize:'2.5rem', marginLeft:'45%'}}/>
                              <Card 
                                sx={{margin:'10px', backgroundColor:'#e1ffe1', borderRadius:'5px'}}
                                >
                                  <CardContent>
                                    
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: '2rem', textAlign:'center'}}>
                                      <LocalShippingIcon sx={{fontSize:'1.5rem'}}/> Managing Logistics
                                    </Typography>
                                    <Typography variant="body1" component="p" sx={{fontSize:'1.3rem', textAlign:'left'}}>
                                    All parties have access to the information - spare availability, vessel location and ETA.
                                    This allows for cost-effective logistics using consolidations and standard services.
                                    
                                    </Typography>
                                    <Typography variant="body1" component="p" sx={{marginTop:'20px', fontSize:'1.3rem'}}>
                                    Consolidating shipments from a central warehouse to regional hubs is significantly cheaper than sending individual packages to each ship. 
                                    </Typography>
                                  </CardContent>
                              </Card>
                            </Grid>
                        </Grid>
                  </Grid>    
                  <Grid item xs={12} sm={12} md={2} lg={2} ipad={12}></Grid>            
              </Grid>
              <Divider variant="middle" sx={{marginTop:'50px'}}/>
              <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} ipad={12}>
                      <Typography variant="h2" component="p" color='primary' sx={{ fontWeight:'bold', fontSize:'4rem', marginTop:'10px', textAlign:'center', textDecoration:'underline'}}>PROCESS</Typography>
                  </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} ipad={12}>
                    <Box sx={{margin:'10px', width:'100%'}}>
                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: '2rem', textAlign:'center'}}>
                              <BuildIcon sx={{fontSize:'1.5rem'}}/> Engineering
                        </Typography>

                        <Card 
                          sx={{margin:'10px', marginLeft:'auto', marginRight:'auto', backgroundColor:'#718d881f', borderRadius:'20px', textAlign:'center',width:{sm:'100%',xs:'100%',md:'20%', lg:'20%'}}}
                          >
                            <CardContent>
                              <Typography variant="body1" component="p" sx={{textAlign:'center', fontSize:'1.3rem'}}>Identify need for parts by ship</Typography>
                            </CardContent>
                        </Card>
                      </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} ipad={12}>
                    <Box sx={{margin:'10px', width:'100%'}}>
                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: '2rem', textAlign:'center'}}>
                              <RequestQuoteIcon sx={{fontSize:'1.5rem'}}/> Procurement
                        </Typography>
                    </Box>
                </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} ipad={12}>
                      <Box sx={{margin:'10px', width:'100%'}}>
                          <Card 
                            sx={{margin:'10px', backgroundColor:'#800080', float:'left', borderRadius:'20px',width:{sm:'100%',xs:'100%',md:'45%', lg:'45%'}}}
                            >
                              <CardContent>
                                <Typography variant="body1" component="p" sx={{textAlign:'center', fontSize:'1.3rem', color:'white'}}>Order part for delivery to central warehouse</Typography>
                              </CardContent>
                          </Card>
                          <ArrowRightIcon sx={{fontSize:'2.5rem', marginTop:'30px', float:'left', width:'5%'}}/>
                          <Card 
                            sx={{margin:'10px', backgroundColor:'#800080', float:'left', borderRadius:'20px', width:{sm:'100%',xs:'100%',md:'45%', lg:'45%'}}}
                            >
                              <CardContent>
                                <Typography variant="body1" component="p" sx={{textAlign:'center', fontSize:'1.3rem', color:'white'}}>updates app with spare part information</Typography>
                              </CardContent>
                          </Card>
                      </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} ipad={12}>
                    <Box sx={{margin:'10px', width:'100%'}}>
                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: '2rem', textAlign:'center'}}>
                              <LocalShippingIcon sx={{fontSize:'1.5rem'}}/> Logistics
                        </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} ipad={12}>
                      <Box sx={{margin:'10px', width:'100%'}}>
                            <Card 
                              sx={{margin:'10px', backgroundColor:'#c99940', float:'left', borderRadius:'20px',width:{sm:'100%',xs:'100%',md:'45%', lg:'45%'}}}
                              >
                                <CardContent>
                                  <Typography variant="body1" component="p" sx={{textAlign:'center', fontSize:'1.3rem', color:'white'}}>Organise shipment to regional hub once ship's destination is known</Typography>
                                </CardContent>
                            </Card>
                            <ArrowRightIcon sx={{fontSize:'2.5rem', marginTop:'30px', float:'left', width:'5%'}}/>
                            <Card 
                              sx={{margin:'10px', backgroundColor:'#c99940', float:'left', borderRadius:'20px', width:{sm:'100%',xs:'100%',md:'45%', lg:'45%'}}}
                              >
                                <CardContent>
                                  <Typography variant="body1" component="p" sx={{textAlign:'center', fontSize:'1.3rem', color:'white'}}>updates app with onward delivery information</Typography>
                                </CardContent>
                            </Card>
                      </Box>
                            

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} ipad={12}>
                    <Box sx={{margin:'10px', width:'100%'}}>
                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: '2rem', textAlign:'center'}}>
                              <MemoryIcon sx={{fontSize:'1.5rem'}}/> AIS/Operations/Ship Master
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} ipad={12}>
                      <Box sx={{margin:'10px', width:'100%'}}>

                        <Card 
                          sx={{margin:'10px', marginLeft:'auto', marginRight:'auto', backgroundColor:'#4157cd', borderRadius:'20px', textAlign:'center',width:{sm:'100%',xs:'100%',md:'20%', lg:'20%'}}}
                          >
                            <CardContent>
                              <Typography variant="body1" component="p" sx={{textAlign:'center', fontSize:'1.3rem', color:'white'}}>updates app with arrival information</Typography>
                            </CardContent>
                        </Card>
                      </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} ipad={12}>
                    <Box sx={{margin:'10px', width:'100%'}}>
                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: '2rem', textAlign:'center'}}>
                              <PersonPinIcon sx={{fontSize:'1.5rem'}}/> Agent
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} ipad={12}>
                    <Box sx={{margin:'10px', width:'100%'}}>
                          <Card 
                            sx={{margin:'10px', backgroundColor:'#1d5e1d', borderRadius:'20px', width:{sm:'100%',xs:'100%',md:'20%', lg:'20%'}, float:'left'}}
                            >
                              <CardContent>
                                <Typography variant="body1" component="p" sx={{textAlign:'center', fontSize:'1.3rem', color:'white'}}>Collects parts once custom cleared, stores in hub warehouse and updates app</Typography>
                              </CardContent>
                          </Card>
                          <ArrowRightIcon sx={{fontSize:'2.5rem', marginTop:'30px', float:'left', width:'5%'}}/>
                          <Card 
                            sx={{margin:'10px', backgroundColor:'#1d5e1d', borderRadius:'20px', width:{sm:'100%',xs:'100%',md:'20%', lg:'20%'}, float:'left'}}
                            >
                              <CardContent>
                                <Typography variant="body1" component="p" sx={{textAlign:'center', fontSize:'1.3rem', color:'white'}}>Arranges delivery on board once vessel arrives at the port and updates app</Typography>
                              </CardContent>
                          </Card>
                          <ArrowRightIcon sx={{fontSize:'2.5rem', marginTop:'30px', float:'left', width:'5%'}}/>
                          <Card 
                            sx={{margin:'10px', backgroundColor:'#1d5e1d', borderRadius:'20px', width:{sm:'100%',xs:'100%',md:'20%', lg:'20%'}, float:'left'}}
                            >
                              <CardContent>
                                <Typography variant="body1" component="p" sx={{textAlign:'center', fontSize:'1.3rem', color:'white'}}>Records delivery on board and updates app</Typography>
                              </CardContent>
                          </Card>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} ipad={12}>
                    <Box sx={{margin:'10px', width:'100%'}}>
                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: '2rem', textAlign:'center'}}>
                              <AppsIcon sx={{fontSize:'1.5rem'}}/> The App
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} ipad={12}>
                      <Box sx={{margin:'10px', width:'100%'}}>
                            <Card 
                              sx={{margin:'10px', backgroundColor:'#4ea54e', float:'left', borderRadius:'20px',width:{sm:'100%',xs:'100%',md:'15%', lg:'15%'}}}
                              >
                                <CardContent>
                                  <Typography variant="body1" component="p" sx={{textAlign:'center', fontSize:'1.3rem', color:'white'}}>Provides visibility of the operating regions of different ships in the fleet</Typography>
                                </CardContent>
                            </Card>
                            <ArrowRightIcon sx={{fontSize:'2.5rem', marginTop:'30px', float:'left', width:'5%'}}/>
                            <Card 
                              sx={{margin:'10px', backgroundColor:'#4ea54e', float:'left', borderRadius:'20px', width:{sm:'100%',xs:'100%',md:'15%', lg:'15%'}}}
                              >
                                <CardContent>
                                  <Typography variant="body1" component="p" sx={{textAlign:'center', fontSize:'1.3rem', color:'white'}}>Identify and recommend potential consolidated shipments to regional hubs</Typography>
                                </CardContent>
                            </Card>
                            <ArrowRightIcon sx={{fontSize:'2.5rem', marginTop:'30px', float:'left', width:'5%'}}/>
                            <Card 
                              sx={{margin:'10px', backgroundColor:'#4ea54e', float:'left', borderRadius:'20px', width:{sm:'100%',xs:'100%',md:'15%', lg:'15%'}}}
                              >
                                <CardContent>
                                  <Typography variant="body1" component="p" sx={{textAlign:'center', fontSize:'1.3rem', color:'white'}}>Provides greater visibility of incoming shipments to simplify collections</Typography>
                                </CardContent>
                            </Card>
                            <ArrowRightIcon sx={{fontSize:'2.5rem', marginTop:'30px', float:'left', width:'5%'}}/>
                            <Card 
                              sx={{margin:'10px', backgroundColor:'#4ea54e', float:'left', borderRadius:'20px', width:{sm:'100%',xs:'100%',md:'15%', lg:'15%'}}}
                              >
                                <CardContent>
                                  <Typography variant="body1" component="p" sx={{textAlign:'center', fontSize:'1.3rem', color:'white'}}>Provides visibility of incoming vessels</Typography>
                                </CardContent>
                            </Card>
                            <ArrowRightIcon sx={{fontSize:'2.5rem', marginTop:'30px', float:'left', width:'2%'}}/>
                            <Card 
                              sx={{margin:'10px', backgroundColor:'#4ea54e', float:'left', borderRadius:'20px', width:{sm:'100%',xs:'100%',md:'15%', lg:'15%'}}}
                              >
                                <CardContent>
                                  <Typography variant="body1" component="p" sx={{textAlign:'center', fontSize:'1.3rem', color:'white'}}>Provides visibility and ETA of arriving vessels</Typography>
                                </CardContent>
                            </Card>
                      </Box>
                            

                </Grid>
            </Grid>
      </>
              
    );
}