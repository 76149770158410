import { useEffect, useState } from "react";
import {Paper, Typography, Grid} from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CustomAlert from '../components/alert';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';

export default function AddPart({token}){
    const statuses = [ "ordered",
        "in transit",
        "arrived",
        "in stock",
        "repositioned",
        "delivered",
    ];
    const [locations, setLocations] = useState([]);
    const [fleet, setFleet] = useState([]);
    const[loading, setLoading] = useState(true);
    const requiredFormFields = [
        {'spare_id': true},
        {'location_name': true},
        {'imo': true},
        {'ship_name': true},
        {'description': true},
        {'act_kg': true},
        {'currency_symbol': true},
        {'purchase_cost': true},
        {'other_cost': true},
        {'status': true},
        {'received': true},
        {'dispatched': true},
    ];
    const [errors, setErrors] = useState({
        spare_id: false,
        location_name: false,
        imo: false,
        ship_name: false,
        description: false,
        act_kg: false,
        currency_symbol: false,
        purchase_cost: false,
        other_cost: false,
        status: false,
        received: false,
        dispatched: false,
    });
    const [errorMessages, setErrorMessages] = useState({
        spare_id: '',
        location_name: '',
        imo: '',
        ship_name: '',
        description: '',
        act_kg: '',
        currency_symbol: '',
        purchase_cost: '',
        other_cost: '',
        status: '',
        received: '',
        dispatched: '',


    });
    const [successMessage, setSuccessMessage] = useState('');
    const[ success, setSuccess] = useState(false);
    const [data, setData] = useState({
        spare_id: "",
        location_name: "",
        imo: 0,
        ship_name: "",
        description: "",
        act_kg: "",
        currency_symbol: "",
        purchase_cost: "",
        other_cost: "",
        status: "",
        received: "",
        dispatched: "",
    })
    // add ship and fleet drop down
    const handelSubmit = () => {
        let formValid= true;
        requiredFormFields.forEach((field) => {
            const key = Object.keys(field)[0];
            if(field[key] && data[key] === ''){
                setErrors(oldErrors => { return {...oldErrors, [key]: true}});
                setErrorMessages(oldErrorMessages => { return {...oldErrorMessages, [key]: `${key} is required`}});
                formValid = false;
            }
        });
        if(!formValid){
            return;
        }
        // remove spare_id from data object
        fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/spare/${data.spare_id}/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify(data)
            }
        )
        .then(response => {
            if(response.status === 400){
                return response.text().then((text) => {
                    const textResponse = JSON.parse(text);
                    console.log(textResponse);
                    setErrorMessages(oldErrorMessages => {
                        return {
                            ...oldErrorMessages,
                            spare_id: textResponse.spare_id ? textResponse.spare_id[0] : '',
                            location_name: textResponse.location_name ? textResponse.location_name[0] : '',
                            imo: textResponse.imo ? textResponse.imo[0] : '',
                            ship_name: textResponse.ship_name ? textResponse.ship_name[0] : '',
                            description: textResponse.description ? textResponse.description[0] : '',
                            act_kg: textResponse.act_kg ? textResponse.act_kg[0] : '',
                            currency_symbol: textResponse.currency_symbol ? textResponse.currency_symbol[0] : '',
                            purchase_cost: textResponse.purchase_cost ? textResponse.purchase_cost[0] : '',
                            other_cost: textResponse.other_cost ? textResponse.other_cost[0] : '',
                            status: textResponse.status ? textResponse.status[0] : '',
                            received: textResponse.received ? textResponse.received[0] : '',
                            dispatched: textResponse.dispatched ? textResponse.dispatched[0] : '',
                        }
                    });
                    throw new Error(text);  // Throw error to catch block
                });
            }
            return response.json();
        })
        .then(data => {
                setSuccess(true);
                setSuccessMessage('Spare added successfully');
                setData(data);
                setErrors({
                    spare_id: false,
                    location_name: false,
                    imo: false,
                    ship_name: false,
                    description: false,
                    act_kg: false,
                    currency_symbol: false,
                    purchase_cost: false,
                    other_cost: false,
                    status: false,
                    received: false,
                    dispatched: false,
                })
                setErrorMessages({
                    spare_id: '',
                    location_name: '',
                    imo: '',
                    ship_name: '',
                    description: '',
                    act_kg: '',
                    currency_symbol: '',
                    purchase_cost: '',
                    other_cost: '',
                    status: '',
                    received: '',
                    dispatched: '',
                });
            }
        )
        .catch(error => {
            console.log(error)
        })
    }
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/fleet/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            const formattedFleetData = data.map((item) => {    
                return {label: `${item.imo}-${item.ship_name}`, id: item.imo}
            });
            setFleet(formattedFleetData);
        })
        .catch((error) => {
            console.error('Failed to fetch data:', error);
        })
        setLoading(false);
    }, [token])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/locations/?d=y`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            const newData = data.map((item) => {
                return {label: item.location_name, id: item.location_name}
            })

            setLocations(newData);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }
    ,[token]);
    


    return (
        <Grid container spacing={2} sx={{marginBottom:'20px'}}>
            <Grid item xs={12}>
                    {loading ? <CircularProgress /> :
                         <>
                            <Paper style={{ padding: '10px', marginTop:'2%'}}>
                                {success ? <CustomAlert success message={successMessage} disappear={true}/>:null}
                                <Typography variant="h4" component="h1">Add a new spare</Typography>
                                <TextField
                                    required
                                    id="spare_id"
                                    label="ID"
                                    error={errors.spare_id}
                                    onChange={event => {
                                        if(isNaN(event.target.value)){
                                            setErrors({...errors, spare_id: true})
                                            setErrorMessages({...errorMessages, spare_id: 'id must be a number'})
                                        }else{
                                            setData({...data,spare_id: event.target.value})
                                            setErrors({...errors,spare_id: false})
                                            setErrorMessages({...errorMessages, spare_id: ''})
                                        }
                                    }}
                                    value={data.spare_id}
                                    fullWidth
                                    margin='normal'
                                    helperText={errorMessages.spare_id}
                                />
                                <Autocomplete
                                    disablePortal
                                    id="location-name"
                                    options={locations}
                                    sx={{ width: 300, marginTop: '10px'}}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        if(newValue.id === undefined){
                                            setErrors({...errors, location_name: true})
                                            setErrorMessages({...errorMessages,location_name: 'location name is required'})
                                        }else{
                                            setData({...data, location_name: newValue.id})
                                            setErrors({...errors,location_name: false})
                                            setErrorMessages({...errorMessages, location_name: ''})
                                        }
                                    }}
                                    renderInput={params =>   
                                        <TextField
                                            {...params}
                                            required
                                            label="Select Location"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    }
                                    isOptionEqualToValue={(option, value) => option.id === value.id} // To properly compare options
                                    style={{width:'100%'}}
                                />
                                 <Autocomplete
                                    disablePortal
                                    id="imo"
                                    options={fleet}
                                    sx={{ width: 300, marginTop: '15px'}}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        if(newValue.id === undefined){
                                            setErrors({...errors, imo: true})
                                            setErrorMessages({...errorMessages,imo: 'imo is required'})
                                        }else{
                                            setData({...data, imo: newValue.id, ship_name: newValue.label.split('-')[1]})
                                            setErrors({...errors,imo: false})
                                            setErrorMessages({...errorMessages, imo: '0'})
                                        }
                                    }}
                                    renderInput={params =>   
                                        <TextField
                                        required
                                            {...params}
                                            label="Select a vessel"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    }
                                    isOptionEqualToValue={(option, value) => option.id === value.id} // To properly compare options
                                    style={{width:'100%'}}
                                />
                                <TextField
                                    id="description"
                                    required
                                    label="Description"
                                    error={errors.description}
                                    onChange={event => setData({...data,description: event.target.value})}
                                    value={data.description}
                                    fullWidth
                                    margin='normal'
                                    helperText={errorMessages.description}
                                />
                                <TextField
                                    required
                                    id="act_kg"
                                    label="Actual weight"
                                    error={errors.act_kg}
                                    onChange={event => {
                                        if(isNaN(event.target.value)){
                                            setErrors({...errors, act_kg: true})
                                            setErrorMessages({...errorMessages, act_kg: 'kg must be a number'})
                                        }else{
                                            setData({...data,act_kg: event.target.value})
                                            setErrors({...errors, act_kg: false})
                                            setErrorMessages({...errorMessages,act_kg: ''})
                                        }
                                    }}
                                    value={data.act_kg}
                                    fullWidth
                                    margin='normal'
                                    helperText={errorMessages.act_kg}
                                />
                                <TextField
                                    required
                                    id="currency_symbol"
                                    label="Currency symbol"
                                    onChange={event => setData({...data,currency_symbol: event.target.value})}
                                    value={data.currency_symbol}
                                    fullWidth
                                    margin='normal'
                                />
                                <TextField
                                    required
                                    id="purchase_cost"
                                    label="Purchase cost"
                                    error={errors.purchase_cost}
                                    onChange={event => {
                                        if(isNaN(event.target.value)){
                                            setErrors({...errors, purchase_cost: true})
                                            setErrorMessages({...errorMessages, purchase_cost: 'cost must be a number'})
                                        }else{
                                            setData({...data,purchase_cost: event.target.value})
                                            setErrors({...errors, purchase_cost: false})
                                            setErrorMessages({...errorMessages, purchase_cost: ''})
                                        }
                                    }}
                                    value={data.purchase_cost}
                                    fullWidth
                                    margin='normal'
                                    helperText={errorMessages.purchase_cost}
                                />
                                <TextField
                                    required
                                    id="other_cost"
                                    label="Other cost"
                                    error={errors.other_cost}
                                    onChange={event => setData({...data,other_cost: event.target.value})}
                                    value={data.other_cost}
                                    fullWidth
                                    margin='normal'
                                    helperText={errorMessages.other_cost}
                                />
                                <TextField
                                    required
                                    id="received"
                                    label="Received"
                                    error={errors.received}
                                    onChange={event => setData({...data,received: event.target.value})}
                                    value={data.received}
                                    fullWidth
                                    margin='normal'
                                    helperText={errorMessages.received}
                                />
                                <TextField
                                    required
                                    id="dispatched"
                                    label="Dispatched"
                                    error={errors.dispatched}
                                    onChange={event => setData({...data,dispatched: event.target.value})}
                                    value={data.dispatched}
                                    fullWidth
                                    margin='normal'
                                    helperText={errorMessages.dispatched}
                                />
                                <TextField
                                    required
                                    id="status"
                                    label="Status"
                                    select
                                    value={data.status}
                                    fullWidth
                                    margin='normal'
                                    onChange={event => setData({...data,status: event.target.value})}
                                >
                                {statuses.map((status, index) => (
                                    <MenuItem key={index} value={status}>{status}</MenuItem>
                                ))}
                                </TextField>
                            </Paper>
                            <Button variant="contained" sx={{marginTop:'5px'}} color='primary' onClick={handelSubmit}>Submit</Button>
                        </>}
            </Grid>
        </Grid>
    )
}