import React, {useEffect, useState} from 'react';
import { MapContainer, TileLayer, useMap} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';


const UpdateCenterComponent = ({ data }) => {
    const map = useMap();

    useEffect(() => {
        if (data && data.length > 0) {
            const middle = Math.floor(data.length / 2);
            const newCenter = [data[middle].lat, data[middle].lng];
            map.setView(newCenter, map.getZoom());
        }
    }, [data, map]);
    return null;
};



const Legend = ({content}) => {
    const map = useMap();

    useEffect(() => {
        const legend = L.control({ position: 'bottomleft' });

        legend.onAdd = () => {
          return content;
        };

        legend.addTo(map);

        // Clean up the legend when the component is unmounted
        return () => {
            map.removeControl(legend);
        };
    }, [map, content]);

    return null;
};

export default function Map({layers=undefined, mapCenterData=undefined, legendContent=undefined, center=[51.505, -0.09], zoom=8, radius=4, height='80vh'}) {
    const mapRef = React.useRef();
    const [layersState, setLayers] = useState([]);
    const [legendContentState, setLegendContent] = useState(null);
    useEffect(() => {
        if (layers) {
            setLayers(layers);
            setLegendContent(legendContent);
        }
    }
    , [layers, legendContent]);
    return (
        <MapContainer  zoom={zoom} center={center} scrollWheelZoom={true} style={{ height: height, minWidth: "100%", marginTop:'5px' }} whenCreated={(map) => { mapRef.current = map; }}>
           <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <UpdateCenterComponent data={mapCenterData}/>
            {layersState? layersState.map((layer, index) => layer): null}
            {legendContentState? <Legend content={legendContentState}/>:null}
           

        </MapContainer>
    )
}



