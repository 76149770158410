import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid';
import {Paper, Typography} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { FormGroup } from '@mui/material';
import { Input } from '@mui/material';
import { FormHelperText } from '@mui/material';
import { Link} from 'react-router-dom';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ConstructionIcon from '@mui/icons-material/Construction';
import Button from '@mui/material/Button';
import CustomAlert from '../components/alert';




export default function ConsoleDetail({token}){
    const {id} = useParams();
    const [data , setData] = useState(undefined);
    const [loading , setLoading] = useState(true);
    const [spares, setSpares] = useState([]);
    const [sparesLookup, setSparesLookup] = useState([]);
    const [selectedSpare, setSelectedSpare] = useState(undefined);
    const[success, setSuccess] = useState(false);
    const[error, setError] = useState(false);
    const[successMessage, setSuccessMessage] = useState('');
    const[errorMessage, setErrorMessage] = useState('');
    const checkedItemsRef = useRef({});
    const [fieldErrorMessages, setFieldErrorMessages] = useState({
        origin: '',
        location: '',
        mode: '',
        eta: '',
        arrived: '',
        status:''
    });

    const handleSpareChecked = (event, spare) => {
        if(spare.spare_id in checkedItemsRef.current){
            checkedItemsRef.current[spare.spare_id] = {
                ...checkedItemsRef.current[spare.spare_id],
                [event.target.name]: event.target.checked
            }
        }else{
            checkedItemsRef.current[spare.spare_id] = {
                [event.target.name]: event.target.checked
            }
        }

    }

    const filterSpare = (spareDetail) => {
        setSuccess(false);
        setSelectedSpare(undefined);
        if(spareDetail){
            const filter = data.spares.filter((item) => Number(item.spare_id) === Number(spareDetail.id));
            // if not in current list then add it
            if(filter.length === 0){
                const spare = spares.filter((item) => Number(item.spare_id) === Number(spareDetail.id));
                console.log(spare);
                setSelectedSpare(spare[0]);
            }
        }
    }

    const addSpare = () => {
        if(selectedSpare){
            const finalData = {...data};
            finalData.spares.push(selectedSpare);
            checkedItemsRef.current[`${selectedSpare.spare_id}`] = {
                ...checkedItemsRef.current[`${selectedSpare.spare_id}`],
                [`${selectedSpare.spare_id}`]: true
            }
            setData(finalData);
            setSuccess(true);
            setSuccessMessage('Item added successfully');
        }
        updateSpares();
    }

    const updateSpares = () => {
        setLoading(true);
        const sparesToKeep = [];
        Object.keys(checkedItemsRef.current).forEach((spare) => {
              if(checkedItemsRef.current[spare][spare]){
                    sparesToKeep.push(spare);
                }
        });
        let finalSpares = data.spares.filter((spare) => sparesToKeep.includes(spare.spare_id));
        finalSpares = finalSpares.map((spare) => spare.spare_id);
        const finalData = {...data};
        finalData.spares = finalSpares;

        setSuccess(false);
        setError(false);
        setFieldErrorMessages({
            origin: '',
            location: '',
            mode: '',
            eta: '',
            dispatched: '',
            status:''
        });
        // console.log(spares);
        fetch( `${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/consol/${id}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Token ${token}`
            },
            body: JSON.stringify(finalData)
        })
        .then(async response => {
            if(response.status !== 200){
                return await response.text().then(text => {
                    const parsedErrors = JSON.parse(text);
                    const newFieldErrors = { ...fieldErrorMessages }; // Start with existing state
            
                    // Populate newFieldErrors with parsed error messages
                    Object.keys(parsedErrors).forEach((key) => {
                        newFieldErrors[key] = parsedErrors[key][0]; // Assign the first error message for each field
                    });
            
                    // Update the state once after the loop
                    setFieldErrorMessages(newFieldErrors);
                    console.log(newFieldErrors); // Log the updated errors for debugging
            
                    throw new Error('Bad request');
                });
            }
            return response.json();
        })
        .then(data => {
            if(data.spares){
                data.spares.forEach((spare) => {
                    checkedItemsRef.current[`${spare.spare_id}`] = {
                        ...checkedItemsRef.current[`${spare.spare_id}`],
                        [`${spare.spare_id}`]: true
                    }
                })
            }else{
                data.spares = [];
            }
                
            setData(data);
            setLoading(false);
            setSuccess(true);
            setSuccessMessage('Console updated successfully');
        })
        .catch((error) => {
            console.error('Error:', error);
            setLoading(false);
            setError(true);
            setErrorMessage('Failed to update console');
        });
    }


    useEffect(() => {
        fetch( `${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/consol/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Token ${token}`
            }

        })
        .then(response => response.json())
        .then(data => {
            if(data.spares){
                data.spares.forEach((spare) => {
                    checkedItemsRef.current[`${spare.spare_id}`] = {
                        ...checkedItemsRef.current[`${spare.spare_id}`],
                        [`${spare.spare_id}`]: true
                    }
                })
            }else{
                data.spares = [];
            }
            setData(data);
            fetch( `${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/spares/${data.origin_name}?page_size=200&page=1&inactive=N`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Token ${token}`
                }
            })
            .then(response2 => response2.json())
            .then(data2 => {
                const formattedSpares = data2.results.map((spare) => ({
                    id: spare.spare_id,
                    label: `${spare.spare_id} - ${spare.description}`
                }));
                setSpares(data2.results);
                setSparesLookup(formattedSpares);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
            setLoading(false);
        });
    }, [id, token]);

    

    return (
        <Grid container spacing={2}>
             {loading ? (
                <Grid item xs={12} sx={{textAlign:'center', marginTop:'1%'}}>
                    <CircularProgress size={100}/>
                </Grid>
            ) : (
            <>
                <Grid item xs={12} sx={{textAlign:'center',  marginTop:'.5%'}}>
                    {success ? <CustomAlert success message={successMessage} disappear={true}/>:null}
                    {error ? <CustomAlert error message={errorMessage} disappear={false}/>:null}

                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" component="h4" sx={{textAlign:'center', textDecoration:'underline'}}>Consol Detail</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={2} lg={2} custom={12} surface={12} >
                            <Button variant="contained" color="primary" size="large" sx={{marginLeft:'13px'}} onClick={updateSpares}>Update</Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} custom={12} surface={12} >
                            <Autocomplete
                                    disablePortal
                                    id="spare"
                                    options={sparesLookup?sparesLookup:[]}
                                    sx={{ width: '100%', marginLeft:'13px' }}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={params =>   
                                        <TextField
                                            {...params}
                                            label="Select a item"
                                            variant="outlined"
                                            fullWidth
                                            helperText={`List of item in the ${data?data.origin_name:''}`} 
                                        />
                                    }
                                    onChange={(event, newValue) => filterSpare(newValue)}
                                    isOptionEqualToValue={(option, value) => option.id === value.id} // To properly compare options
                                    fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2} custom={12} surface={12} >
                            <Button variant="contained" color="primary" size="large" sx={{marginLeft:'13px'}} onClick={addSpare}>ADD ITEM</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <Grid container spacing={2} sx={{marginTop:'1%'}} >
                                <Grid item xs={12} sm={12} md={2} lg={2} custom={12} surface={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="origin">Origin</InputLabel>
                                        <Input id="origin" aria-describedby="Where the shipment originatges from" value={data?data.origin_name:''} sx={{marginLeft:'13px'}}/>
                                        <FormHelperText id="Where the shipment originatges from">Where the shipment originatges from</FormHelperText>
                                        {fieldErrorMessages.origin && <FormHelperText error>{fieldErrorMessages.origin}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} custom={12} surface={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="location">Location</InputLabel>
                                        <Input id="location" aria-describedby="Shipment destination" value={data?data.new_location_name:''} sx={{marginLeft:'13px'}}/>
                                        <FormHelperText id="Shipment destination">Shipment destination</FormHelperText>
                                        {fieldErrorMessages.location && <FormHelperText error>{fieldErrorMessages.location}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} custom={12} surface={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="mode">Mode</InputLabel>
                                        <Input id="mode" aria-describedby="The type of shipment (express/standard)" margin="dense" value={data?data.mode:''} sx={{marginLeft:'13px'}}/>
                                        <FormHelperText id="The type of shipment (express/standard)" margin="dense">The type of shipment (express/standard)</FormHelperText>
                                        {fieldErrorMessages.mode && <FormHelperText error>{fieldErrorMessages.mode}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} custom={12} surface={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="eta">Target date</InputLabel>
                                        <Input id="eta" aria-describedby="Minimum date by which your shipment should arrive" margin="dense" value={data?data.target_eta:''} sx={{marginLeft:'13px'}}/>
                                        <FormHelperText id="Minimum date by which your shipment should arrive" margin="dense">Minimum date by which your shipment should arrive</FormHelperText>
                                        {fieldErrorMessages.eta && <FormHelperText error>{fieldErrorMessages.eta}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}  md={2} lg={2} custom={12} surface={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="dispatched">Dispatched date</InputLabel>
                                        <Input onChange={(e)=> setData({...data,dispatched:e.target.value})} id="dispatched-date" aria-describedby="Minimum date by which your shipment should arrive" margin="dense" defaultValue={data?data.dispatched:''} sx={{marginLeft:'13px'}} />
                                        <FormHelperText id="dispatched-date" margin="dense">When are you shipping these items</FormHelperText>
                                        {fieldErrorMessages.dispatched && <FormHelperText error>{fieldErrorMessages.dispatched}</FormHelperText>}
                                        
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} custom={12} surface={12}>
                                    <Autocomplete
                                        disablePortal
                                        id="status"
                                        options={[{"id":"in_process","label":"in process"},{"id":"arrived","label":"arrived"}]}
                                        sx={{ width: '100%',marginLeft:'13px' }}
                                        getOptionLabel={(option) => option.label}
                                        value={data?{"id":data.status,"label":data.status.replace("_", " ")}:{id:'',label:'Select a status'}}
                                        onChange={(event, newValue) => {setData({...data, status:newValue.id})}}
                                        renderInput={params =>   
                                            <TextField
                                                {...params}
                                                label="Select a status"
                                                variant="standard"
                                                fullWidth
                                            />
                                        }
                                        isOptionEqualToValue={(option, value) => option.id === value.id} // To properly compare options
                                        style={{width:'100%'}}
                                    />
                                </Grid>
                        </Grid>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sx={{marginBottom:'2%'}}>
                    <Typography variant="h6" component="h6" sx={{marginLeft:'13px', marginBottom:'10px', textDecoration:'underline'}}>Console items</Typography>
                    <Grid container spacing={2}>
                        {data && data.spares.length>0?
                            data.spares.map((spare, index) => (
                                <Grid item xs={12} sm={12} custom={12} md={4} lg={4} surface={12}  key={index}>
                                    <Paper elevation={3} sx={{padding:'1%',marginLeft:'13px'}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <ConstructionIcon fontSize="small" color="primary" />
                                                <Typography variant="body1" fontWeight={'bold'}>
                                                        Spare id: {spare.spare_id}
                                                </Typography>
                                                <Typography variant="body2" fontWeight={'bold'}>
                                                        IMPA: {spare.impa}
                                                </Typography>
                                                <Typography variant="body2">
                                                        Description: {spare.description}
                                                </Typography>
                                                <Typography variant="body2" >
                                                        Urgent: {spare.urgent?'Yes':'No'}
                                                </Typography>
                                                <Link to={`/part/${spare.spare_id}`}>
                                                    <Button variant="contained" color="primary" size="small">
                                                        More information
                                                    </Button>
                                                </Link>
                                                <FormControlLabel onChange={(event)=>handleSpareChecked(event,spare)} control={<Checkbox name={spare.spare_id} defaultChecked={true} checked={checkedItemsRef[spare.spare_id]} />} label="Consolidate" sx={{marginLeft:'10px'}}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <DirectionsBoatIcon fontSize="small" color="primary" />
                                                <Typography variant="body1" fontWeight={'bold'}>
                                                    Ship name: {spare.ship_name}
                                                </Typography>
                                                <Typography variant="body2">
                                                    IMO: {spare.imo}
                                                </Typography>
                                                <Link to={`/ship/detail/${spare.imo}`}>
                                                    <Button variant="contained" color="primary" size="small" sx={{marginTop:{md:'11%',lg:'11%'}}}>
                                                        Details
                                                    </Button>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            ))
                            
                        : <Grid item xs={12} sm={12} md={12} lg={12}><Typography variant="h6" component="h6" sx={{textAlign:'center', marginTop:'1%'}}>No spares found</Typography></Grid>}
                    </Grid>
                </Grid>
            </>
        )}
    </Grid>
    )
}