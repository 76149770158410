import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import {Paper, Typography, Grid} from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CustomAlert from '../components/alert';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';



export default function Part({token}){
    const statuses = [ "ordered",
        "in process",
        "arrived",
        "in stock",
        "repositioned",
        "delivered",
    ];
    const {id} = useParams();
    const [spare, setSpare] = useState(null);
    const [ports, setPorts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const[ success, setSuccess] = useState(false);
    const[fieldValid, setFieldValid] = useState(null);
    const updateStatus = () => {
        setSuccess(false);
        setError(false);
        fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/spare/${id}/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify(spare)
            }
        )
        .then(response => {
            if(response.status === 400){
                return response.text().then((text) => {
                let fieldErrors = {};
                   Object.keys(JSON.parse(text)).map((key) => {
                        fieldErrors[key] = {
                            valid: false,
                            message: JSON.parse(text)[key][0],
                            color: 'warning'
                        }
                        let tempFieldValid = fieldValid;
                        tempFieldValid[key] = fieldErrors[key];
                        setFieldValid(prevState => {
                            return {
                                ...prevState,
                                tempFieldValid
                            }
                        });
                        return null;
                    }
                    );
                    // form form level error banner to now show as these are field errors
                    setError(false);
                   throw new Error('Please fill in all required fields');
                });
            }
            if(response.status === 401){
                throw new Error('You are not authorized to perform this action');
            }
            if(response.status === 404){
                throw new Error('Spare not found');
            }
            if(response.status === 500){
                return response.text().then((text) => {
                    setError(true);
                    setErrorMessage(text);
                    throw new Error(text);
                });
            }
            return response.json();
        })
        .then(data => {
                setSpare(data);
                setLoading(false);
                setSuccess(true);
                setSuccessMessage('Spare updated successfully');
                setFieldValid({
                    spare_id:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    act_kg:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    chargeable_kg:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    currency_symbol:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    description:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    delivered:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    location_name:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    other_cost:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    purchase_cost:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    received:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    ship_name:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    imo: {
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    status: {
                        valid: true,
                        error: '',
                        color: '',
                        message:'Please select status',
                    },
                    local_port: {
                        valid: true,
                        error: '',
                        color: '',
                        message:'Please select a port',
                    },
                    urgent: {
                        valid: true,
                        error: '',
                        color: '',
                        message:'Please select urgency',
                    },
                });
            }
        )
        .catch(error => {
            console.log(error);
            setLoading(false);
        });
    }
    useEffect(() => {
        setFieldValid({
            spare_id:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            act_kg:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            chargeable_kg:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            currency_symbol:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            description:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            delivered:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            location_name:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            other_cost:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            purchase_cost:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            received:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            ship_name:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            imo: {
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            status: {
                valid: true,
                error: '',
                color: '',
                message:'Please select status',
            },
            urgent: {
                valid: true,
                error: '',
                color: '',
                message:'Please select urgency',
            },
        });
        fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/spare/${id}/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            }
        )
        .then(response => response.json())
        .then(data => {
            console.log(data);
                setSpare(data);
                setLoading(false);
            }
        )
        .catch(error => {
            setError(true);
            setErrorMessage(error.message);
            setLoading(false);
        });
    }, [id, token]);
    

    useEffect(() => {
        const fetchData = async () => {
          let portsLookup = [];
          try {
            const [portsResponse] = await Promise.all([
              fetch(`${process.env.REACT_APP_BACKEND_URL}/ports/list/`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${token}`
                }
              }),
            ]);
    
            if (!portsResponse.ok) {
              throw new Error('Network response was not ok');
            }
    
            const [portsData] = await Promise.all([
              portsResponse.json()
            ]);
            if(portsData.length > 0){    
                // sort alphabetically
                portsData.sort((a, b) => {
                    if(a.name < b.name){
                        return -1;
                    }
                    if(a.name > b.name){
                        return 1;
                    }
                    return 0;
                });
                portsLookup = portsData.map((port) => {
                    return {
                        id: port.port_id,
                        label: `${port.name}-${port.UN_code}`
                    }
                });
                portsLookup.push(
                    {
                        id: '',
                        label: ''
                    }
                )
                setPorts(portsLookup);
            }
          } catch (error) {
            console.error('Failed to fetch data:', error);
          } finally {
            console.log('done')
          }
        };
    
        fetchData();
      }, [token]);

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.label,
      });


    return (
        <Grid item xs={12} style={{ marginTop: '5px', marginBottom:'1%'}}>
            {loading ? (<CircularProgress />) : (
                <>
                {success ? <CustomAlert success message={successMessage} disappear={true}/>:null}
                {error ? <CustomAlert error message={errorMessage} disappear={false}/>:null}
                <Typography variant="h4" component="h4" sx={{marginTop:'2%'}}>Details</Typography>
                <Paper style={{ padding: '10px'}}>
                    <TextField
                        required
                        id="shipId"
                        inputProps={{readOnly:true}}
                        label="ID"
                        value={spare.spare_id}
                        fullWidth
                        margin='normal'
                        // error={!fieldValid.spare_id.valid}
                        // color={fieldValid.spare_id.color}
                        // helperText={fieldValid.spare_id.message}
                        // focused={!fieldValid.spare_id.valid}
                
                    />
                     <TextField
                        required
                        id="description"
                        inputProps={{readOnly:true}}
                        label="Description"
                        value={spare.description}
                        fullWidth
                        margin='normal'
                        error={!fieldValid.description.valid}
                        color={fieldValid.description.color}
                        helperText={fieldValid.description.message}
                        focused={!fieldValid.description.valid}
                
                        />
                     <TextField
                     required
                        id="shipName"
                        label="Ship Name"
                        onChange={event => setSpare({...spare,ship_name: event.target.value})}
                        value={spare.ship_name}
                        fullWidth
                        margin='normal'
                        error={!fieldValid.ship_name.valid}
                        color={fieldValid.ship_name.color}
                        helperText={fieldValid.ship_name.message}
                        focused={!fieldValid.ship_name.valid}
                        />
                    <TextField
                        required
                        id="weight"
                        onChange={event => setSpare({...spare, act_kg: event.target.value})}
                        label="Weight in kgs"
                        type='number'
                        value={spare.act_kg}
                        fullWidth
                        margin='normal'
                        error={!fieldValid.act_kg.valid}
                        color={fieldValid.act_kg.color}
                        helperText={fieldValid.act_kg.message}
                        focused={!fieldValid.act_kg.valid}
 
                        />
                    <TextField
                        required
                        id="chargeable_kg"
                        label="Weight chargeable"
                        value={spare.chargeable_kg}
                        fullWidth
                        margin='normal'
                        onChange={event => setSpare({...spare, chargeable_kg: event.target.value})}
                        error={!fieldValid.chargeable_kg.valid}
                        color={fieldValid.chargeable_kg.color}
                        helperText={fieldValid.chargeable_kg.message}
                        focused={!fieldValid.chargeable_kg.valid}
                        
               
                        />
                    <TextField
                        required
                        id="purchase_cost"
                        label="Purchase cost"
                        fullWidth
                        margin='normal'
                        value={spare.purchase_cost}
                        onChange={event => setSpare({...spare, purchase_cost: event.target.value})}
                        />
                    <TextField
                        required
                        id="other_cost"
                        label="Other cost"
                        value={spare.other_cost}
                        fullWidth
                        margin='normal'
                        onChange={event => setSpare({...spare, other_cost: event.target.value})}
                        />
                    <TextField
                        required
                        inputProps={{readOnly:false}}
                        id="currency_symbol"
                        label="Currency"
                        value={spare.currency_symbol}
                        fullWidth
                        margin='normal'
                        />
                    <TextField
                        required
                        id="received"
                        label="Received on"
                        value={spare.received}
                        fullWidth
                        margin='normal'
                        onChange={event => setSpare({...spare, received: event.target.value})}
                        error={!fieldValid.received.valid}
                        color={fieldValid.received.color}
                        helperText={fieldValid.received.message}
                        focused={!fieldValid.received.valid}
                        />
                    <TextField
                        required
                        id="delivered"
                        label="Delivered on"
                        value={spare.delivered}
                        error={!fieldValid.delivered.valid}
                        color={fieldValid.delivered.color}
                        focused={!fieldValid.delivered.valid}
                        helperText={fieldValid.delivered.message}
                        fullWidth
                        margin='normal'
                        onChange={event => setSpare({...spare, delivered: event.target.value})}
                        />
                     <TextField
                        required
                        id="status"
                        label="Status"
                        error={!fieldValid.status.valid}
                        color={fieldValid.status.color}
                        select
                        value={spare.status}
                        fullWidth
                        margin='normal'
                        helperText={fieldValid.status.message}
                        onChange={event => setSpare({...spare, status: event.target.value})}
                        focused={!fieldValid.status.valid}
                        >
                        {statuses.map((status, index) => (
                            <MenuItem key={index} value={status}>{status}</MenuItem>
                        ))}
                    </TextField>
                    {/* bug here to be fixed */}
                    <Autocomplete
                            disablePortal
                            id="ports"
                            options={ports?ports:[]}
                            sx={{ width: '100%', marginTop: '10px'}}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, value) => {setSpare({...spare, local_port: value? value.label:''})}}
                            value={spare && spare.local_port===''? {id:'',label:''}: {id:-1, label:spare.local_port}}
                            renderInput={params =>   
                                <TextField
                                    {...params}
                                    label="Select a port the part has been repositioned to"
                                    variant="outlined"
                                    fullWidth
                                />
                            }
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                        {option.label}
                                    </li>
                                );
                            }}
                            filterOptions={filterOptions}
                            style={{width:'100%'}}
                    />
                    <Autocomplete
                            disablePortal
                            id="urgent"
                            options={[{id:"yes", label:"Yes"}, {id:"no", label:"No"}]}
                            sx={{ width: '100%', marginTop: '20px'}}
                            getOptionLabel={(option) => option.id==="yes"? "Yes":"No"}
                            onChange={(event, value) => {setSpare({...spare, urgent: value && value.label==='Yes'? true:false})}}
                            value={spare && spare.urgent? {id:"yes", label:"Yes"}:{id:"no", label:"No"}}
                            renderInput={params =>   
                                <TextField
                                    {...params}
                                    label="Urgent"
                                    variant="outlined"
                                    fullWidth
                                />
                            }
                            style={{width:'100%'}}
                            isOptionEqualToValue={(option, value) => option.id === value.id} // To properly compare options
                    />
                </Paper>
                    <Button variant="contained" color="primary" style={{marginTop:'10px'}} onClick={updateStatus}>Update</Button>
                </>
            )}
        </Grid>
    )
}