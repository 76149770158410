import React, { useCallback, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import {Paper,  Grid} from '@mui/material';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import Slider from '@mui/material/Slider';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import SearchIcon from '@mui/icons-material/Search';



export default function Parts({token}){
    const [loading, setLoading] = useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] =  useState(10);
    const [spares, setSpares] = useState([]);
    const [locationLookup, setlocationLookup] = useState([]);
    const [shipLookup, setShipLookup] = useState([]);
    const[oldSpares, setOldSpares] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [inactiveParts, setInactiveParts] = useState(false);
    const [inactivePartsFlag, setInactivePartsFlag] = useState("N");
    const [locationName, setLocationName] = useState("*");
    const[agereport, setAgereport] = useState(0);

    const handelChecked = (event) => {
        setInactiveParts(event.target.checked)
        if(event.target.checked){
            setInactivePartsFlag("Y")
        }else{
            setInactivePartsFlag("N")
        }
        getParts({token});
    }

    const filterParts = (event, newVal) => {
        if(!newVal){
            setSpares(oldSpares);
            setLocationName("*");
            return
        }
        setLoading(true);
        setLocationName(newVal.id);
        let url =`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/spares/${newVal.id}/?page_size=10&inactive=${inactivePartsFlag}&agereport=${agereport}`;
        fetch(
            url,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            }
        )
        .then(response => response.json())
        .then(data => {
                setSpares(data.results);
                setNextPage(data.next);
                setPageCount(data.count);
                setLoading(false);  
        })
        .catch(e => {
            console.error(e);

            setLoading(false);
        });
    }

    const filterPartsByIMO = (event, newVal) => {
        let url = "";
        // when imo filter is null and no location filter set then return to original dataset
        if(!newVal && locationName==="*"){
            setSpares(oldSpares);
            return
        }
        // when imo filter is null and location filter has value filter then filter by location
        if(!newVal){
            url =`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/spares/${locationName}/?page_size=10&inactive=${inactivePartsFlag}&agereport=${agereport}`;
        }
        if(newVal){
            url =`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/spares/${locationName}/${newVal.id}/?page_size=10&inactive=${inactivePartsFlag}&agereport=${agereport}`;
        }
        setLoading(true);
        fetch(
            url,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            }
        )
        .then(response => response.json())
        .then(data => {
                setSpares(data.results);
                setNextPage(data.next);
                setPageCount(data.count);
                setLoading(false);  
        })
        .catch(e => {
            console.error(e);

            setLoading(false);
        });
    }

    const handelAgeReportChange = (event, value) => {
        setAgereport(value);
        getParts({token});
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setLoading(true);
        let url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/spares/?page=${newPage+1}&page_size=10&inactive=${inactivePartsFlag}&agereport=${agereport}`;
        if(locationName){
            url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/spares/${locationName}/?page=${newPage+1}&page_size=10&inactive=${inactivePartsFlag}&agereport=${agereport}`;
        }
        fetch(
            url,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            }
        )
        .then(response => response.json())
        .then(data => {
                setSpares(data.results);
                setNextPage(data.next);
                setPageCount(data.count);
                setLoading(false);  
        })
        .catch(e => {
            console.error(e);
            setLoading(false);  
        });
    };
    
    const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    };
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/locations/?d=y`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            const formattedLocationData = data.map((location) => {
                return {
                    id: location.location_name,
                    label: `${location.location_name}`
                }
            });
            setlocationLookup(formattedLocationData);
        })
        .catch(e => {
            console.error(e);
        });
    }
    , [token]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/fleet/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            const shipLookup = data.map((ship) => {
                return {
                    id: ship.imo,
                    label: `${ship.ship_name}`
                }
            });
            setShipLookup(shipLookup);
        })
        .catch((error) => {
            console.error('Failed to fetch data:', error);
        })
        setLoading(false);
    }, [token])
    const getParts = useCallback(({token}) => {
        fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/spares/?page_size=10&inactive=${inactivePartsFlag}&agereport=${agereport}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            }
        )
        .then(response => response.json())
        .then(data => {
                setSpares(data.results);
                setOldSpares(data.results);
                setNextPage(data.next);
                setPageCount(data.count);
                setLoading(false); 
        })
        .catch(e => {
            console.error(e);
            setLoading(false);  
        });
    }, [inactivePartsFlag, agereport]);

    useEffect(() => {
        getParts({token});
    },[token, getParts]);
    return (
        <>
        <Grid container spacing={2} style={{ marginTop: '5px' }}>
            <Grid item xs={12} md={2} lg={2} sx={{marginTop:'2%'}}>
                <Button variant="contained" component={Link} to='/part/add' color='primary'>Add new part</Button>
            </Grid>
            <Grid item xs={12} md={2} lg={2} sx={{marginTop:'2%'}}>
                <FormControlLabel control={<Checkbox checked={inactiveParts} onChange={handelChecked}/>} label="Include inactive parts" sx={{marginLeft:'2px'}}/>
            </Grid>
            <Grid item xs={12} md={2} lg={2} sx={{marginTop:'1%'}}>
                <Tooltip title="only include parts over selected months old" arrow placement="top">
                    <Typography id="agereport" gutterBottom sx={{textAlign:'center'}}>
                        Report age (months)
                    </Typography>
                </Tooltip>
                <Slider aria-labelledby="agereport" value={agereport} onChange={handelAgeReportChange} defaultValue={0} step={3} valueLabelDisplay="on" max={12}/>
            </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '5px' }}>
            <Grid item xs={12} lg={6}>
                <SearchIcon/>
                {locationLookup.length?
                <Autocomplete
                    disablePortal
                    id="search-by-id"
                    options={locationLookup}
                    sx={{ width: 300 }}
                    getOptionLabel={(option) => option.label}
                    onChange={filterParts}
                    renderInput={params =>   
                        <TextField 
                            {...params}
                            label="Search by location name"
                            variant="outlined"
                            fullWidth
                        />
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id} // To properly compare options
                    style={{width:'100%'}}
                />:null}
            </Grid>
            <Grid item xs={12} lg={6}>
                <SearchIcon/>
                {shipLookup.length?<Autocomplete
                    disablePortal
                    id="search-by-imo"
                    options={shipLookup}
                    sx={{ width: 300 }}
                    getOptionLabel={(option) => option.label}
                    onChange={filterPartsByIMO}
                    renderInput={params =>   
                        <TextField 
                            {...params}
                            label="Search by ship name"
                            variant="outlined"
                            fullWidth
                        />
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id} // To properly compare options
                    style={{width:'100%'}}
                />:null}
            </Grid>
            <Grid item xs={12}>
                {loading ? (
                <CircularProgress />
                ) : (
                
                <>
                    <TableContainer component={Paper} sx={{marginTop:'10px' }}>
                        <TablePagination
                            rowsPerPageOptions={[10, 15, 25, 100]}
                            component="div"
                            count={pageCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        <Table sx={{ minWidth: 650}} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Location name</TableCell>
                                <TableCell>Vessel</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell >Actual&nbsp;(kg)</TableCell>
                                <TableCell >Purchase cost</TableCell>
                                <TableCell>Received</TableCell>
                                <TableCell >Urgent</TableCell>
                                <TableCell >Status</TableCell>
                                <TableCell ></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {spares.length && spares.map((row) => (
                                    <TableRow
                                    key={row.spare_id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    
                                    >

                                        <TableCell component="th" scope="row">
                                            {row.location_name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {`${row.ship_name} ( ${row.imo} )`}
                                        </TableCell>
                                        <TableCell >{row.description}</TableCell>
                                        <TableCell >{row.act_kg}</TableCell>
                                        <TableCell >{`${row.currency_symbol}- ${row.purchase_cost}`}</TableCell>
                                        <TableCell >{row.received}</TableCell>
                                        {row.urgent? <TableCell sx={{backgroundColor:'red',color:'white'}}>Yes</TableCell>:<TableCell sx={{backgroundColor:'green',color:'white'}}>No</TableCell>}
                                        <TableCell >{row.status}</TableCell>
                                        <TableCell >
                                            <Link to={`/part/${row.spare_id}`}>
                                                    <Button variant="contained" color='primary'>Details</Button>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
                )}
            </Grid>
    </Grid>
    </>
    )
}