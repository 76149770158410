import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import {Paper, Typography} from '@mui/material';
import { Link} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomAlert from '../components/alert';


export default function InProcessConsols({token}) {
    const [consols, setConsols] = useState([]);
    const [loading , setLoading] = useState(true);
    const[success, setSuccess] = useState(false);
    const[error, setError] = useState(false);
    const[successMessage, setSuccessMessage] = useState('');
    const[errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setLoading(true);
        setSuccess(false);
        setError(false);
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/consols/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Token ${token}`
            }
        }).then(response => {
            if(response.status === 404){
                setLoading(false);
                return [];
            }
            return response.json();
        })
        .then(data => {
            setConsols(data);
            setLoading(false);
        })
        .catch(error => {
            setLoading(false);
            console.error('Error:', error);
        });
    }, [token]);


    const handelDelete = (id) => {
        setLoading(true);
        setSuccess(false);
        setError(false);
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/consols/?consol_id=${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Token ${token}`
            }
        }).then(response => {
            if(response.status === 404){
                setLoading(false);
                return [];
            }
            return response.json();
        })
        .then(data => {
                setConsols(data);
                setSuccess(true);
                setSuccessMessage('Consol deleted successfully');
                setLoading(false);
        })
        .catch(error => {
            setError(true);
            setErrorMessage('Failed to delete consol');
            setLoading(false);
            console.error('Error:', error);
        });
    }

    return (
       <Grid container spacing={2} sx={{marginTop:'1%'}}>
          <Grid item xs={12}>
            {loading? 
                <Grid item xs={12} sx={{textAlign:'center', marginTop:'1%'}}>
                    <CircularProgress size={100}/>
                </Grid>:
                <>
                    <Grid item xs={12} sx={{textAlign:'center'}}>
                        {success ? <CustomAlert success message={successMessage} disappear={true}/>:null}
                        {error ? <CustomAlert error message={errorMessage} disappear={false}/>:null}

                    </Grid>
                    <Grid item xs={12} sx={{textAlign:'center'}}>
                         <Typography variant="h4">Consols</Typography>
                    </Grid>
                    <Grid container spacing={2}>
                        {consols.length > 0? consols.map((consol, index) => (
                            <Grid key={`${index}-grid`}item xs={12} md={3} lg={3}>
                                <Paper key={`${index}-paper`} elevation={3} style={{padding: 10, margin: 10}}>
                                        <Typography variant="h5" >New Location: {consol.new_location_name}</Typography>
                                        <Typography variant="body1">Origin: {consol.origin_name}</Typography>
                                        <Typography variant="body1">Mode: {consol.mode}</Typography>
                                        <Typography variant="body1">Capacity: {consol.target_eta}</Typography>
                                        <Typography variant="body1">Status: {consol.status.replace("_", " ")}</Typography>
                                        <Button variant="contained" component={Link}  to={`/consol/detail/${consol.id}`}>Details</Button>
                                        <Button variant="contained" sx={{marginLeft:'5px'}} startIcon={<DeleteIcon/>} onClick={()=> handelDelete(consol.id)}>Delete</Button>
                                </Paper>
                            </Grid>
                    )):<Grid item xs={12} sm={12} md={12} lg={12}><Typography variant="h2" component="h2" sx={{textAlign:'center', marginTop:'1%'}}>No consols found!!!</Typography></Grid>}
                    </Grid>
                </>
            }
          </Grid>
       </Grid>
    );
}